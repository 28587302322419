import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../contexts/authContext';
import { states } from '../../states.js';
import { Timestamp } from 'firebase/firestore';
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function AdminInterface() {
  const [pendingRestaurants, setPendingRestaurants] = useState([]);
  const { currentUser } = useAuth();
  const [errors, setErrors] = useState({});
  const [editingRestaurant, setEditingRestaurant] = useState(null);
  const [newDiet, setNewDiet] = useState('');
  const [dietError, setDietError] = useState('');
  const [dietSuccess, setDietSuccess] = useState('');

  useEffect(() => {
    fetchPendingRestaurants();
  }, [currentUser]);

  const fetchPendingRestaurants = async () => {
    try {
      const token = await currentUser.getIdToken();
  
      const response = await fetch(`${REACT_APP_BACKEND_URL}/pending-restaurants`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to fetch pending restaurants: ${errorData.error || response.statusText}`);
      }
  
      const data = await response.json();
      setPendingRestaurants(data);
    } catch (error) {
      console.error('Error fetching pending restaurants:', error);
      setErrors(prev => ({...prev, general: `Failed to load pending restaurants: ${error.message}`}));
    }
  };

  const handleApprove = async (id) => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/approve-restaurant/${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        }
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to approve restaurant');
      }
      fetchPendingRestaurants(); // Refresh the list
      setErrors(prev => ({...prev, [id]: null})); // Clear error for this restaurant
    } catch (error) {
      console.error('Error approving restaurant:', error);
      setErrors(prev => ({...prev, [id]: error.message}));
    }
  };

  const handleReject = async (id) => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/reject-restaurant/${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to reject restaurant');
      }
      fetchPendingRestaurants(); // Refresh the list
      setErrors(prev => ({...prev, [id]: null})); // Clear error for this restaurant
    } catch (error) {
      console.error('Error rejecting restaurant:', error);
      setErrors(prev => ({...prev, [id]: error.message}));
    }
  };

  const handleEdit = (restaurant) => {
    setEditingRestaurant({ ...restaurant });
  };

  const handleInputChange = (e, field) => {
    setEditingRestaurant({
      ...editingRestaurant,
      [field]: e.target.value
    });
  };

  const handleAddressChange = (e, field) => {
    setEditingRestaurant({
      ...editingRestaurant,
      address: {
        ...editingRestaurant.address,
        [field]: e.target.value
      }
    });
  };

  const handleSave = async () => {
    try {
      // Create a copy of the editingRestaurant object
      const updatedRestaurant = { ...editingRestaurant };

      // Update search terms
      updatedRestaurant.searchName = updatedRestaurant.name.toLowerCase();
      updatedRestaurant.searchCuisine = updatedRestaurant.cuisine.toLowerCase();
      updatedRestaurant.address.searchCity = updatedRestaurant.address.city.toLowerCase();
      updatedRestaurant.address.searchStateAbbrv = updatedRestaurant.address.stateAbbrv.toLowerCase();

      // If stateFull is available, update searchStateFull
      if (updatedRestaurant.address.stateFull) {
        updatedRestaurant.address.searchStateFull = updatedRestaurant.address.stateFull.toLowerCase();
      }

      const response = await fetch(`${REACT_APP_BACKEND_URL}/update-pending-restaurant/${updatedRestaurant.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedRestaurant)
      });
      if (!response.ok) {
        throw new Error('Failed to update restaurant');
      }
      fetchPendingRestaurants(); // Refresh the list
      setEditingRestaurant(null); // Exit edit mode
      setErrors(prev => ({...prev, [updatedRestaurant.id]: null})); // Clear error for this restaurant
    } catch (error) {
      console.error('Error updating restaurant:', error);
      setErrors(prev => ({...prev, [editingRestaurant.id]: error.message}));
    }
  };

  const handleAddDiet = async () => {
    try {
      setDietError('');
      setDietSuccess('');
      
      const response = await fetch(`${REACT_APP_BACKEND_URL}/add-diet-to-restaurants`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        },
        body: JSON.stringify({ diet: newDiet.toLowerCase() })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to add diet');
      }

      setDietSuccess(data.message);
      setNewDiet('');
    } catch (error) {
      console.error('Error adding diet:', error);
      setDietError(error.message);
    }
  };

  return (
    <div className='border-t-2 bg-gray-100 min-h-screen'>
      <Helmet>
        <title>Admin Dashboard | FeelGoodEats</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="FeelGoodEats admin dashboard. Authorized access only." />
      </Helmet>
      
      <div className="container mx-auto p-4">
      
        <div className="bg-white shadow-md rounded px-8 py-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Restaurant Entry Guidelines</h2>
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold text-gray-700 mb-2">Capitalization Rules:</h3>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Restaurant Name should be properly capitalized (e.g., "The Hungry Vegan")</li>
                <li>Cuisine type should be capitalized (e.g., "Italian", "Chinese")</li>
                <li>Street name should be capitalized (e.g., "Main St")</li>
                <li>City name should be capitalized (e.g., "Boston")</li>
                <li>State abbreviation should be capitalized (e.g., "MA")</li>
              </ul>
            </div>

            <div>
              <h3 className="font-semibold text-gray-700 mb-2">Address Formatting:</h3>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Street abbreviations:
                  <ul className="list-none pl-5 text-gray-500">
                    <li>- Street → St</li>
                    <li>- Road → Rd</li>
                    <li>- Avenue → Ave</li>
                    <li>- Boulevard → Blvd</li>
                    <li>- Drive → Dr</li>
                  </ul>
                </li>
                <li>State should be abbreviated (e.g., "MA" not "Massachusetts")</li>
                <li>ZIP code should be 5 digits</li>
              </ul>
            </div>

            <div>
              <h3 className="font-semibold text-gray-700 mb-2">Contact Information:</h3>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Website should include full URL (e.g., "https://www.restaurant.com")</li>
                <li>Phone number should be formatted as (123) 456-7890</li>
              </ul>
            </div>

            <div>
              <h3 className="font-semibold text-gray-700 mb-2">Example Entry:</h3>
              <div className="bg-gray-50 p-3 rounded text-gray-600">
                <p>Name: The Green Kitchen</p>
                <p>Cuisine: Mediterranean</p>
                <p>Website: https://www.thegreenkitchen.com</p>
                <p>Phone: (617) 555-0123</p>
                <p>Address: 123 Main St, Boston, MA 02108</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded px-8 py-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Add New Diet Type</h2>
          <div className="mb-4">
            <input
              type="text"
              value={newDiet}
              onChange={(e) => setNewDiet(e.target.value)}
              placeholder="Enter new diet type"
              className="border rounded px-2 py-1 mr-2"
            />
            <button
              onClick={handleAddDiet}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Add Diet
            </button>
          </div>
          {dietError && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{dietError}</span>
            </div>
          )}
          {dietSuccess && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{dietSuccess}</span>
            </div>
          )}
        </div>

        <h1 className="text-2xl font-bold mb-4">Pending Restaurants</h1>
        
        {errors.general && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errors.general}</span>
          </div>
        )}
        {pendingRestaurants.map((restaurant) => (
          <div key={restaurant.id} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {editingRestaurant && editingRestaurant.id === restaurant.id ? (
              // Edit mode
              <>
                <p><span className="font-bold">Name:</span> <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.name} onChange={(e) => handleInputChange(e, 'name')} /></p>
                <p><span className="font-bold">Cuisine:</span> <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.cuisine} onChange={(e) => handleInputChange(e, 'cuisine')} /></p>
                <p><span className="font-bold">Website:</span> <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.website || ''} onChange={(e) => handleInputChange(e, 'website')} /></p>
                <p><span className="font-bold">Phone:</span> <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.phone || ''} onChange={(e) => handleInputChange(e, 'phone')} /></p>
                <p><span className="font-bold">Address:</span></p>
                <p>Street: <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.address.street} onChange={(e) => handleAddressChange(e, 'street')} /></p>
                <p>City: <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.address.city} onChange={(e) => handleAddressChange(e, 'city')} /></p>
                <p>State: <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.address.stateAbbrv} onChange={(e) => handleAddressChange(e, 'stateAbbrv')} /></p>
                <p>Zip: <input className="border rounded px-2 py-1 mb-1" value={editingRestaurant.address.zip} onChange={(e) => handleAddressChange(e, 'zip')} /></p>
                <div className="mt-4">
                  <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">Save</button>
                  <button onClick={() => setEditingRestaurant(null)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>
                </div>
              </>
            ) : (
              // View mode
              <>
                <p><span className="font-bold">Name:</span> {restaurant.name}</p>
                <p><span className="font-bold">Cuisine:</span> {restaurant.cuisine}</p>
                <p><span className="font-bold">Website (optional):</span> {restaurant.website || 'N/A'}</p>
                <p><span className="font-bold">Phone (optional):</span> {restaurant.phone || 'N/A'}</p>
                <p><span className="font-bold">Address:</span> {restaurant.address.street}, {restaurant.address.city}, {restaurant.address.stateAbbrv} {restaurant.address.zip}</p>
                <div className="mt-4">
                  <button onClick={() => handleEdit(restaurant)} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2">Edit</button>
                  <button onClick={() => handleApprove(restaurant.id)} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">Approve</button>
                  <button onClick={() => handleReject(restaurant.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Reject</button>
                </div>
              </>
            )}
            {errors[restaurant.id] && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{errors[restaurant.id]}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminInterface;
