import { Helmet } from 'react-helmet';

function AboutPage() {
  return (
    <div className="border-t-2 min-h-screen bg-gray-100">
      <Helmet>
        <title>About | FeelGoodEats</title>
        <meta name="description" content="Learn about FeelGoodEats, your go-to resource for diet-specific restaurant reviews." />
        <meta property="og:title" content="About | FeelGoodEats" />
        <meta property="og:description" content="Learn about FeelGoodEats, your go-to resource for diet-specific restaurant reviews." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.feelgoodeats.net/about" />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <h1 className="text-3xl font-bold mb-6 text-center">About FeelGoodEats</h1>
        
        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
          <p className="mb-4">
            We realized that for many people with dietary restrictions, whether due to allergies, health conditions, or personal choices, dining out can be a challenging and sometimes stressful experience. FeelGoodEats was born from a simple observation: there wasn't a centralized place for people to find diet-specific restaurant reviews across all types of diets.
          </p>
          <p className="mb-4">
            What began as a simple idea quickly evolved into a platform with a clear mission.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
          <p className="mb-4">
            At FeelGoodEats, our mission is to help you find great places to eat, no matter your diet. We strive to:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide a comprehensive platform for diet-specific restaurant reviews</li>
            <li>Foster a community of food lovers who help each other out</li>
            <li>Encourage restaurants to cater to diverse dietary needs</li>
            <li>Make dining out an enjoyable experience for everyone, regardless of their dietary requirements</li>
          </ul>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-16">
          <h2 className="text-2xl font-semibold mb-4">Join Our Community</h2>
          <p className="mb-4">
            Whether you're vegan, gluten-free, keto, or anything else, FeelGoodEats is here for you. By sharing your experiences, you're helping others find great places to eat. And by reading others' reviews, you're discovering new restaurants that cater to your own dietary needs.
          </p>
          <p className="mb-4">
            We invite you to explore restaurants, write reviews, and be part of our growing community. Let's make finding delicious, diet-friendly food easier for everyone!
          </p>
        </section>
      </div>
    </div>
  );
}

export default AboutPage;