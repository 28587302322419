import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Review from '../review/Review.js';
import diets from '../../diets.js';
import backgroundImage from '../../images/restaurant-scene.jpg';
import StarRating from '../star-rating/StarRating.js';
import Gallery from '../gallery/Gallery.js';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function RestaurantPage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialDiet = queryParams.get('diet') || 'non-specific';

  const [restaurantData, setRestaurantData] = useState(null);
  const [dietType, setDietType] = useState(initialDiet);
  const [averageOverallRating, setAverageOverallRating] = useState(0);
  const [averageTasteRating, setAverageTasteRating] = useState(0);
  const [averageOptionsRating, setAverageOptionsRating] = useState(0);
  const [averageComfortRating, setAverageComfortRating] = useState(0);
  const [numReviews, setNumReviews] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const batchSize = 5; // Number of reviews per page

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        const response = await fetch(`${REACT_APP_BACKEND_URL}/restaurant/${id}`);
        const data = await response.json();
        setRestaurantData(data);
        updateReviewStats(data, dietType);
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
      }
    };

    fetchRestaurantData();
  }, [id, dietType]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/reviews?` + 
          `id=${id}&` +
          `dietType=${dietType}&` +
          `pageSize=${batchSize}` +
          (lastVisible ? `&lastVisible=${lastVisible}` : '')
        );
        
        const data = await response.json();
        
        // If we're loading the first page, replace reviews
        // Otherwise, append new reviews to existing ones
        const updatedReviews = lastVisible ? [...reviews, ...data.reviews] : data.reviews;
        setReviews(updatedReviews);
        setGalleryImages(data.galleryImages);
        setLastVisible(data.lastVisible);
        
        // Compare loaded reviews count with total reviews
        setHasMore(updatedReviews.length < numReviews);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setIsLoading(false);
      }
    };

    if (restaurantData) {
      updateReviewStats(restaurantData, dietType);
      fetchReviews();
    }
  }, [id, dietType, restaurantData]);

  const loadMoreReviews = async () => {
    if (!hasMore || isLoading) return;
    
    try {
      setIsLoading(true);
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/reviews?` + 
        `id=${id}&` +
        `dietType=${dietType}&` +
        `pageSize=${batchSize}&` +
        `lastVisible=${lastVisible}`
      );
      
      const data = await response.json();
      const updatedReviews = [...reviews, ...data.reviews];
      setReviews(updatedReviews);
      setLastVisible(data.lastVisible);
      
      // Compare loaded reviews count with total reviews
      setHasMore(updatedReviews.length < numReviews);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading more reviews:', error);
      setIsLoading(false);
    }
  };

  const updateReviewStats = (restaurantData, dietType) => {
    if (restaurantData.reviewStats && restaurantData.reviewStats[dietType]) {
      const dietStats = restaurantData.reviewStats[dietType];
      setAverageOverallRating(dietStats.averageOverall.toFixed(1));
      setAverageTasteRating((dietStats.sumOfRatings.taste / dietStats.totalReviews).toFixed(1));
      setAverageOptionsRating((dietStats.sumOfRatings.options / dietStats.totalReviews).toFixed(1));
      setAverageComfortRating((dietStats.sumOfRatings.comfort / dietStats.totalReviews).toFixed(1));
      setNumReviews(dietStats.totalReviews);
    } else {
      setAverageOverallRating(0);
      setAverageTasteRating(0);
      setAverageOptionsRating(0);
      setAverageComfortRating(0);
      setNumReviews(0);
    }
  };

  const handleDietChange = (event) => {
    const newDiet = event.target.value;
    setDietType(newDiet);
    setReviews([]);
    setLastVisible(null);
    queryParams.set('diet', newDiet);
    navigate({ search: queryParams.toString() });
  };

  if (!restaurantData) {
    return <div className="border-t-2 flex flex-col justify-center"></div>;
  }

  function ensureHttps(url) {
    if (!url) return '';
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  }

  function formatWebsiteForDisplay(url) {
    if (!url) return '';
    return url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
  }

  return (
    <div className="flex flex-col items-center bg-gray-100 p-4 min-h-screen">
      <Helmet>
        <title>{restaurantData ? `${restaurantData.name} | FeelGoodEats` : 'Restaurant | FeelGoodEats'}</title>
        <meta name="description" content={restaurantData ? `${restaurantData.name} in ${restaurantData.address?.city}, ${restaurantData.address?.stateAbbrv}. ${dietType} friendly restaurant. Read reviews and see photos.` : 'View restaurant details, reviews, and photos on FeelGoodEats.'} />
        <meta property="og:title" content={restaurantData ? `${restaurantData.name} | FeelGoodEats` : 'Restaurant | FeelGoodEats'} />
        <meta property="og:description" content={restaurantData ? `${restaurantData.name} in ${restaurantData.address?.city}, ${restaurantData.address?.stateAbbrv}. ${dietType} friendly restaurant. Read reviews and see photos.` : 'View restaurant details, reviews, and photos on FeelGoodEats.'} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={restaurantData?.imageURL || backgroundImage} />
        <meta property="og:url" content={`https://www.feelgoodeats.net/restaurant/${id}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="w-full max-w-6xl px-4 py-10 mt-10 bg-cover relative" 
           style={{ 
             backgroundImage: `url(${backgroundImage})`,
             backgroundPosition: 'center 65%' // Adjust this value to move the image up or down (percent from top)
           }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-white">
          <h1 className="text-4xl font-bold mb-2">{restaurantData?.name}</h1>
          <p className="text-xl mb-4">{restaurantData?.cuisine}</p>
          <p className="mb-1">{restaurantData?.address?.street}</p>
          <p className="mb-4">{restaurantData?.address?.city}, {restaurantData?.address?.stateAbbrv} {restaurantData?.address?.zip}</p>
          {restaurantData?.website ? (
            <a 
              href={ensureHttps(restaurantData.website)} 
              className="text-blue-300 hover:text-blue-100 underline" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {formatWebsiteForDisplay(restaurantData.website)}
            </a>
          ) : (
            <p className="text-gray-400">Website not available</p>
          )}
          {restaurantData?.phone ? (
            <p className="text-white mt-2">{restaurantData.phone}</p>
          ) : (
            <p className="text-gray-400 mt-2">Phone number not available</p>
          )}
        </div>
      </div>

      <div className="w-full max-w-6xl bg-white shadow-lg rounded-lg overflow-hidden my-8">
        <div className="p-6">
          <div className="flex flex-wrap justify-between items-center mb-6">
            <div className="md:w-auto mb-0">
              <select
                id="category"
                name="category"
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={dietType}
                onChange={handleDietChange}
              >
                {diets.map(diet => (
                  <option key={diet} value={diet}>{diet}</option>
                ))}
              </select>
            </div>
            <button 
              onClick={() => setIsGalleryOpen(true)}
              className="bg-blue-500 hover:bg-blue-600 rounded-md px-4 py-2 font-medium text-white shadow-sm"
            >
              View Gallery
            </button>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
            <div className="text-center border-r border-gray-500 pr-6">
              <h2 className="text-base sm:text-lg font-semibold mb-2">Overall</h2>
              <div className="flex justify-center items-center mb-2">
                <span className="text-2xl sm:text-3xl font-bold mr-2">{numReviews > 0 ? averageOverallRating : 'N/A'}</span>
                <StarRating rating={averageOverallRating} size={24} />
              </div>
              <p className="text-sm sm:text-sm text-gray-600">Combined rating of taste, options, and comfort</p>
            </div>
            <div className="text-center pr-6 lg:pr-0">
              <h2 className="text-base sm:text-lg font-semibold mb-2">Taste</h2>
              <div className="flex justify-center items-center mb-2">
                <span className="text-2xl sm:text-3xl font-bold mr-2">{numReviews > 0 ? averageTasteRating : 'N/A'}</span>
                <StarRating rating={averageTasteRating} size={20} />
              </div>
              <p className="text-sm sm:text-sm text-gray-600">Deliciousness of the food for the selected diet</p>
            </div>
            <div className="text-center pr-6 lg:pr-0">
              <h2 className="text-base sm:text-lg font-semibold mb-2">Options</h2>
              <div className="flex justify-center items-center mb-2">
                <span className="text-2xl sm:text-3xl font-bold mr-2">{numReviews > 0 ? averageOptionsRating : 'N/A'}</span>
                <StarRating rating={averageOptionsRating} size={20} />
              </div>
              <p className="text-sm sm:text-sm text-gray-600">Variety of choices for the selected diet</p>
            </div>
            <div className="text-center pr-6 lg:pr-0">
              <h2 className="text-base sm:text-lg font-semibold mb-2">Comfort</h2>
              <div className="flex justify-center items-center mb-2">
                <span className="text-2xl sm:text-3xl font-bold mr-2">{numReviews > 0 ? averageComfortRating : 'N/A'}</span>
                <StarRating rating={averageComfortRating} size={20} />
              </div>
              <p className="text-sm sm:text-sm text-gray-600">Physical well-being after the meal for the selected diet</p>
            </div>
          </div>

          <p className="text-center text-lg mb-6">
            Based on <span className="font-bold">{numReviews}</span> {numReviews === 1 ? 'Review' : 'Reviews'}
          </p>

          <div className="text-center">
            <a href={`/restaurants/${id}/review`} className="inline-block bg-green-500 hover:bg-green-600 rounded-md font-medium px-4 py-2 text-white shadow-sm">
              Write a Review
            </a>
          </div>
        </div>
      </div>
        
      {reviews.length > 0 && (
        <div className="w-full max-w-6xl mb-16">
          <h1 className="text-3xl mb-6" id='reviews'>Reviews</h1>
          {reviews.map((review, index) => (
            <Review
              key={review.id}
              name={review.name}
              body={review.body}
              overall={review.rating.overall.toFixed(1)}
              taste={review.rating.taste.toFixed(1)}
              options={review.rating.options.toFixed(1)}
              comfort={review.rating.comfort.toFixed(1)}
              imageURL={review.imageURL}
              timestamp={review.timestamp}
            />
          ))}
          
          {hasMore && (
            <div className="flex justify-center mt-8">
              <button
                onClick={loadMoreReviews}
                disabled={isLoading}
                className={`rounded-md px-4 py-2 text-white shadow-sm ${
                  isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'
                }`}
              >
                {isLoading ? 'Loading...' : 'Load More Reviews'}
              </button>
            </div>
          )}
        </div>
      )}
      {isGalleryOpen && (
        <Gallery images={galleryImages} onClose={() => setIsGalleryOpen(false)} />
      )}
    </div>
  );
}

export default RestaurantPage;
