import React, { useState, useEffect, useRef } from 'react';
import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';
import StarRating from '../star-rating/StarRating';
import { FaMapMarker } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

function GoogleMap({restaurants, hoveredRestaurant, dietType}) {
  const [mapCenter, setMapCenter] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(true); // Add loading state
  const [clickedRestaurant, setClickedRestaurant] = useState(null); // For tracking hovered marker index
  const markerRefs = useRef([]); // Create a ref to store multiple marker refs
  const [hoveredMarker, setHoveredMarker] = useState(false);
  const [key, setKey] = useState(0); // Add a key to force re-render
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const mapId = process.env.REACT_APP_GOOGLE_MAP_ID;

  useEffect(() => {
    if (restaurants.length > 0) {
      // Filter out restaurants without coordinates
      const restaurantsWithCoords = restaurants.filter(restaurant => restaurant.coordinates);
      
      if (restaurantsWithCoords.length > 0) {
        const totalCoordinates = restaurantsWithCoords.length;
        const sumCoordinates = restaurantsWithCoords.reduce(
          (sum, restaurant) => {
            sum.lat += restaurant.coordinates.lat;
            sum.lng += restaurant.coordinates.lng;
            return sum;
          },
          { lat: 0, lng: 0 }
        );

        setMapCenter({
          lat: sumCoordinates.lat / totalCoordinates,
          lng: sumCoordinates.lng / totalCoordinates,
        });
        setKey(prev => prev + 1); // Force map to re-render with new defaultCenter
      }
    }
    setLoading(false);
  }, [restaurants]);

  if (!apiKey) {
    console.error('Google Maps API key is missing');
    return (
      <div className='max-[900px]:h-[400px] max-[900px]:mt-5 max-[900px]:mb-5 w-full rounded-lg overflow-hidden shadow-md bg-gray-100 flex items-center justify-center'>
        <p className="text-gray-600">Map unavailable</p>
      </div>
    );
  }

  const mapOptions = {
    disableDefaultUI: true,
    clickableIcons: false,
    draggable: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    zoomControl: true,
    fullscreenControl: false,
    gestureHandling: 'greedy',
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      }
    ]
  };

  return (
    <APIProvider apiKey={apiKey}>
      <div className='max-[900px]:h-[400px] max-[900px]:mt-5 max-[900px]:mb-5 w-full rounded-lg overflow-hidden shadow-md'>
        {!loading && mapCenter && mapId && (
          <Map
            key={key}
            mapId={mapId}
            defaultZoom={11}
            defaultCenter={mapCenter}
            options={mapOptions}
          >

            {restaurants.map((restaurant, index) => {
              // Check if the marker ref exists for the current index
              if (!markerRefs.current[index]) {
                markerRefs.current[index] = React.createRef(); // Assign a new ref to the current index
              }
              const markerRef = markerRefs.current[index]; // Get the marker ref for the current index
              return (
                restaurant.coordinates ? (
                  <AdvancedMarker
                    key={index}
                    position={restaurant.coordinates}
                    title={restaurant.name}   
                    ref={markerRef}
                    zIndex={(hoveredRestaurant === restaurant.id || clickedRestaurant === restaurant.id || hoveredMarker === restaurant.id) ? 1 : 0}
                  >
                    <div 
                      className='flex flex-col items-center pointer-events-auto relative'
                    >
                      {(hoveredRestaurant === restaurant.id || clickedRestaurant === restaurant.id || hoveredMarker === restaurant.id) && ( 
                        <div className='pb-5 mb-[-8px] max-w-52 min-w-36' 
                             onMouseOver={() => setHoveredMarker(restaurant.id)}
                             onMouseOut={() => setHoveredMarker(null)}
                        > 
                          <div className='relative flex flex-col w-auto cursor-default bg-white rounded-lg shadow-lg'>
                            <div className='pl-2.5 flex flex-row w-full justify-between items-center'>
                              <h1 className='text-sm font-bold'>{restaurant.name}</h1>
                              <button 
                                className='p-2 text-2xl pointer-events-auto text-gray-600 hover:text-gray-800'
                                onClick={() => {setHoveredMarker(null); setClickedRestaurant(null)}} 
                              >
                                <IoMdClose />
                              </button>
                            </div>
                          
                            <div className='flex flex-row items-center justify-start px-2.5'>
                              <h3 className='font-bold text-sm mr-1'>{restaurant.reviewStats[dietType].totalReviews > 0 ? (restaurant.reviewStats[dietType].sumOfRatings.overall / restaurant.reviewStats[dietType].totalReviews).toFixed(1) : 'N/A'}</h3>
                              <StarRating rating={restaurant.reviewStats[dietType].sumOfRatings.overall} size={15}/>
                            </div>
                      
                            <h3 className='mb-1 px-2.5 text-xs font-light'>({restaurant.reviewStats[dietType].totalReviews} {dietType} {restaurant.reviewStats[dietType].totalReviews === 1 ? 'review' : 'reviews'})</h3>
                            <a href={`/restaurants/${restaurant.id}`} className='px-2.5 pb-2.5 text-sm font-light text-blue-500 hover:underline'>View page</a>

                            <div className='h-4 w-4 rounded-sm bg-white absolute rotate-45 bottom-0 right-1/2 translate-x-1/2 translate-y-1/2'></div>
                          </div>  
                        </div>
                      )}
                      <div 
                        className={`relative hover:text-blue-500 flex flex-col items-center ${clickedRestaurant === restaurant.id ? 'text-blue-500' : 'text-red-500'}`}
                        onClick={() => {setClickedRestaurant(restaurant.id); console.log(restaurant.rating);}}
                        onMouseOver={() => setHoveredMarker(restaurant.id)}
                        onMouseOut={() => setHoveredMarker(null)}
                      >
                        <FaMapMarker className='w-8 h-10 drop-shadow-md' />
                        <h1 className={`inline absolute text-white font-medium ${restaurant.reviewStats[dietType].totalReviews > 0 ? 'text-[15px] text-base top-[4px]' : 'text-[13px] top-[8px]'}`}>{restaurant.reviewStats[dietType].totalReviews > 0 ? (restaurant.reviewStats[dietType].sumOfRatings.overall / restaurant.reviewStats[dietType].totalReviews).toFixed(1) : 'N/A'}</h1>
                      </div>
                    </div>
                  </AdvancedMarker>
                ) : null
              );
            })} 
          </Map>
        )}
      </div>
    </APIProvider>
  )
}

export default GoogleMap;