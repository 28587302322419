import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './contexts/authContext';
import HomePage from './components/home-page/HomePage.js';
import Header from './components/header/Header.js';
import ResultsPage from './components/results-page/ResultsPage.js';
import AddListingPage from './components/add-listing-page/AddListingPage.js';
import RestaurantPage from './components/restaurant-page/RestaurantPage.js';
import AddReviewPage from './components/add-review-page/AddReviewPage.js';
import ProfilePage from './components/profile-page/ProfilePage.js';
import ErrorPage from './components/error-page/ErrorPage.js';
import Footer from './components/footer/Footer.js';
import AdminInterfacePage from './components/admin-interface-page/AdminInterfacePage.js';
import AdminRoute from './components/admin-route/AdminRoute.js'; // To restrict access to admin-only pages
import AboutPage from './components/about-page/AboutPage.js';
import ContactPage from './components/contact-page/ContactPage.js';
import TermsPage from './components/terms-page/TermsPage.js';
import PrivacyPage from './components/privacy-page/PrivacyPage.js';
import ErrorBoundary from './components/error-boundary/ErrorBoundary.js';


function App() {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <div className="App">   
          <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/results" element={<ResultsPage />}/>
            <Route path="/add-listing" element={<AddListingPage />}/>
            <Route path="/restaurants/:id" element={<RestaurantPage />}/>
            <Route path="/restaurants/:id/review" element={<AddReviewPage />}/>
            <Route path="/profile" element={<ProfilePage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/terms' element={<TermsPage />} />
            <Route path='/privacy' element={<PrivacyPage />} />
            <Route path="/admin" element={
                                  <AdminRoute>
                                    <AdminInterfacePage />
                                  </AdminRoute>
                                }/>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter> 
          <Footer />
        </div>
      </ErrorBoundary>
    </AuthProvider>
  );
}

export default App;
