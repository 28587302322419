import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";

function Footer() {
  return (
    <footer className="bg-gray-200 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <a href='/' className="text-2xl font-bold ">FeelGoodEats</a>
          </div>
          <nav className="mb-4 md:mb-0">
            <ul className="flex flex-wrap justify-center gap-4">
              <li><a href='/' className='text-lg hover:underline'>Home</a></li>
              <li><a href='/about' className='text-lg hover:underline'>About</a></li>
              <li><a href='/contact' className='text-lg hover:underline'>Contact</a></li>
              <li><a href='/terms' className='text-lg hover:underline'>Terms of Service</a></li>
              <li><a href='/privacy' className='text-lg hover:underline'>Privacy Policy</a></li>
            </ul>
          </nav>
          <div className="flex gap-4">
            <a href='https://twitter.com/feelgoodeats_' target="_blank" rel="noopener noreferrer" className='cursor-pointer'>
              <FaSquareXTwitter className='w-8 h-8' />
            </a>
            <a href='https://linkedin.com/company/feelgoodeats' target="_blank" rel="noopener noreferrer" className='cursor-pointer'>
              <FaLinkedin className='w-8 h-8' />
            </a>
          </div>
        </div>
        <div className="mt-8 text-center text-sm text-gray-600">
          © {new Date().getFullYear()} FeelGoodEats. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;