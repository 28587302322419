import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { states } from '../../states.js';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default function AddListing() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Retrieve form values
    const name = e.target.elements['name'].value.trim();
    const cuisine = e.target.elements['cuisine'].value.trim();
    const website = e.target.elements['website'].value.trim();
    const phone = e.target.elements['phone'].value.trim();
    const street = e.target.elements['street-address'].value.trim();
    const city = e.target.elements['city'].value.trim();
    const stateAbbrv = e.target.elements['state'].value.trim();
    const zip = e.target.elements['postal-code'].value.trim();
    const country = e.target.elements['country'].value.trim();

    const newErrors = {};

    if (!name) newErrors.name = 'Field is required';
    if (!cuisine) newErrors.cuisine = 'Field is required';
    if (!street) newErrors.street = 'Field is required';
    if (!city) newErrors.city = 'Field is required';
    if (!stateAbbrv) newErrors.state = 'Field is required';
    if (!zip) newErrors.zip = 'Field is required';
    if (!country) newErrors.country = 'Field is required';
   
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If we've made it here, all required fields are filled
    setIsSubmitting(true);

    // Call the Express server to add the new restaurant
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/add-restaurant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, cuisine, website, phone, street, city, stateAbbrv, zip, country }),
      });
  
      const data = await response.json();
  
      if (response.status === 409) {
        setPopupMessage(data.message || 'A restaurant with this name and address already exists.');
        setShowPopup(true);
      } else if (!response.ok) {
        throw new Error(data.message || 'Failed to submit restaurant');
      } else {
        setPopupMessage('We have received your submission!');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to submit restaurant:', error);
      setErrors({ ...errors, submit: error.message });
    } finally {
      setIsSubmitting(false);  // Stop loading regardless of outcome
    }
  };

  const handleGoBack = () => {
    // Hide the pop-up and potentially reset the form or navigate back
    setShowPopup(false);

    // Redirect to the previous page
    window.history.back();
  };

  return (
    <div className='border-t-2 min-h-screen'>
      <Helmet>
        <title>Add a Restaurant | FeelGoodEats</title>
        <meta name="description" content="Add a new restaurant to FeelGoodEats. Help our community discover great places to eat that cater to specific dietary needs." />
        <meta property="og:title" content="Add a Restaurant | FeelGoodEats" />
        <meta property="og:description" content="Add a new restaurant to FeelGoodEats. Help our community discover great places to eat that cater to specific dietary needs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.feelgoodeats.net/add-restaurant" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      
      <div className='mx-4'> 
        <form className='mt-12 mb-24 border-b border-gray-900/10 bg-slate-200 sm:mx-auto sm:max-w-[800px] shadow sm:rounded-lg px-6 py-12 sm:px-12' onSubmit={handleSubmit}>
        
          <h2 className="text-lg font-semibold leading-7 text-gray-900">Restaurant Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">A moderator will approve the request as soon as possible</p>
          <p className="mt-1 text-sm leading-6 text-gray-600 italic">
            Please capitalize all words and use standard abbreviations (St for Street, Rd for Road, Ave for Avenue, etc.). 
            Include full website URL (https://www.example.com) and format phone numbers as (123) 456-7890.
          </p>

          <div className="mt-10 grid grid-cols-1 sm:gap-x-6 gap-y-2 sm:grid-cols-6 grid-rows-8 sm:grid-rows-4">

            <div className="col-span-3 row-start-1 h-[88px]">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">
                Name
              </label>           
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Restaurant Name"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>

            <div className="col-span-3 row-start-2 sm:row-start-1 h-[88px]">
              <label htmlFor="cuisine" className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">
                Cuisine
              </label>       
              <input
                type="text"
                name="cuisine"
                id="cuisine"
                placeholder="e.g., Italian, Mexican, Chinese"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.cuisine && <p className="text-red-500 text-xs mt-1">{errors.cuisine}</p>}
            </div>

            <div className="col-span-3 row-start-3 sm:row-start-2 h-[88px]">
              <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 after:content-['(optional)'] after:font-light">Website </label>     
              <input
                type="text"
                name="website"
                id="website"
                placeholder="https://www.example.com"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="col-span-3 row-start-4 sm:row-start-2 h-[88px]">
              <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 after:content-['(optional)'] after:font-light">Phone </label>     
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="(123) 456-7890"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="col-span-3 row-start-5 sm:row-start-3 h-[88px]">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">
                Street address
              </label>
              <input
                type="text"
                name="street-address"
                id="street-address"
                placeholder="123 Main St"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.street && <p className="text-red-500 text-xs mt-1">{errors.street}</p>}
            </div>

            <div className="col-span-3 row-start-6 sm:row-start-3 h-[88px]">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">
                Country
              </label>
              <select
                id="country"
                name="country"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
              </select>
            </div>

            <div className="col-span-1 sm:col-span-2 sm:col-start-1 row-start-7 sm:row-start-4 h-[88px]">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">
                City
              </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.city && <p className="text-red-500 text-xs mt-1">{errors.city}</p>}
            </div>

            <div className="col-span-1 sm:col-span-2 row-start-8 sm:row-start-4 h-[88px]">
              <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">
                State / Province
              </label>
                <select
                  name="state"
                  id="state"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                  {states.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))}
                </select>
            </div>

            <div className="col-span-1 sm:col-span-2 row-start-9 sm:row-start-4 h-[88px]">
              <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">
                ZIP / Postal code
              </label>
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.zip && <p className="text-red-500 text-xs mt-1">{errors.zip}</p>}
            </div>

          </div>

          <div className="mt-8 sm:mt-6 flex items-center justify-end gap-x-6">
            <button type="button" onClick={handleGoBack} className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        
        </form>
      </div>

      {isSubmitting && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      )}
      

      {/* Pop-up message */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 m-4 max-w-sm w-full animate-fade-in-down">
            <div className="flex flex-col items-center">
              {popupMessage.includes('We have received your submission!') ? (
                <FaCheckCircle className="text-green-500 text-5xl mb-4" />
              ) : (
                <FaExclamationTriangle className="text-yellow-500 text-5xl mb-4" />
              )}
              <h2 className="text-2xl font-bold mb-4 text-center">
                {popupMessage.includes('We have received your submission!') ? 'Thank You!' : 'Oops!'}
              </h2>
              <p className="text-gray-700 text-center mb-6">
                {popupMessage}
              </p>
              {popupMessage.includes('We have received your submission!') ?
                <button
                onClick={handleGoBack}
                className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-500"
                >
                  Go back
                </button> :
                <button 
                onClick={() => setShowPopup(false)}
                className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-500"
                >
                  Try again
                </button> 
              }
            </div>
          </div>
        </div>
      )}

    </div>
   
  )
}
