import { useState } from 'react';

function Gallery({ images, onClose }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="max-w-4xl w-full p-4">
        {images.length == 0 && (
          <div className="flex justify-center items-center h-full">
            <p className="text-xl text-white mb-20">No gallery images available</p>
          </div>
        )}
        {images.length > 0 && (
          <img src={images[currentIndex]} alt="Gallery" className="w-full h-auto max-h-[80vh] object-contain" />
        )}
        <div className="flex justify-center items-center space-x-20 mt-4">
          <button onClick={prevImage} className="bg-white text-black px-4 py-2 rounded">Previous</button>
          <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded">Close</button>
          <button onClick={nextImage} className="bg-white text-black px-4 py-2 rounded">Next</button>
        </div>
      </div>
    </div>
  );
}

export default Gallery;