import { Helmet } from 'react-helmet';

function ErrorPage() { 
  return (
    <>
      <Helmet>
        <title>Page Not Found | FeelGoodEats</title>
        <meta name="description" content="We're sorry, but the page you're looking for cannot be found. Please check the URL or return to the FeelGoodEats homepage." />
        <meta property="og:title" content="Page Not Found | FeelGoodEats" />
        <meta property="og:description" content="We're sorry, but the page you're looking for cannot be found. Please check the URL or return to the FeelGoodEats homepage." />
        <meta property="og:type" content="website" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <main className="border-t-2 grid bg-white px-6 py-24 sm:py-32 lg:px-8 min-h-screen">
        <div className="text-center">
          <p className="text-3xl font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn't find the page you're looking for.</p>
          <div className="mt-10 flex items-center justify-center">
            <a
              href="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go home
            </a>
          </div>
        </div>
      </main>
    </>
  )
}

export default ErrorPage;