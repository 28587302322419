import { Helmet } from 'react-helmet';

function TermsPage() {
  return (
    <div className="border-t-2 min-h-screen bg-gray-100">
      <Helmet>
        <title>Terms of Service | FeelGoodEats</title>
        <meta name="description" content="FeelGoodEats Terms of Service. Read our terms for using our diet-specific restaurant reviews platform." />
        <meta property="og:title" content="Terms of Service | FeelGoodEats" />
        <meta property="og:description" content="FeelGoodEats Terms of Service. Read our terms for using our diet-specific restaurant reviews platform." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.feelgoodeats.net/terms" />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <h1 className="text-3xl font-bold mb-2 text-center">Terms of Service</h1>
        <p className="text-sm text-gray-600 mb-6 text-center">Last Updated: Oct 19, 2024</p>
        
        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
          <p className="mb-4">
            By accessing or using FeelGoodEats, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use our service.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. User Responsibilities</h2>
          <p className="mb-4">
            Users are responsible for:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Providing accurate and truthful information in reviews and user profiles</li>
            <li>Respecting the rights of restaurants and other users</li>
            <li>Complying with all applicable laws and regulations</li>
            <li>Maintaining the confidentiality of their account information</li>
          </ul>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Content Guidelines</h2>
          <p className="mb-4">
            Users agree not to post content that is:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>False, misleading, or defamatory</li>
            <li>Abusive, harassing, or threatening</li>
            <li>In violation of copyright or intellectual property laws</li>
            <li>Spam or commercial solicitation</li>
          </ul>
          <p className="mb-4">
            FeelGoodEats reserves the right to remove any content that violates these guidelines.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
          <p className="mb-4">
            The content and software on FeelGoodEats are protected by copyright and other intellectual property laws. Users may not copy, modify, distribute, or reproduce any part of the service without prior written consent.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
          <p className="mb-4">
            FeelGoodEats is not responsible for the accuracy of user-generated content or any consequences resulting from the use of our service. We do not endorse or guarantee any restaurants or products mentioned in reviews.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-16">
          <h2 className="text-2xl font-semibold mb-4">6. Changes to Terms</h2>
          <p className="mb-4">
            FeelGoodEats reserves the right to modify these Terms of Service at any time. When we do, we will revise the "Last Updated" date at the top of this page. We encourage you to review these terms periodically to stay informed about our practices. Continued use of the service after any modifications constitutes your acceptance of the current terms.
          </p>
        </section>
      </div>
    </div>
  );
}

export default TermsPage;
