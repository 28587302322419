import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import MyReviews from '../my-reviews/MyReviews';
import { Helmet } from 'react-helmet';


function ProfilePage() {
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('profile');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time (remove this in production)
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (!currentUser) {
    return <div className="flex bg-gray-100 border-t-2 min-h-screen justify-center items-center">Please log in to view your profile.</div>;
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100 border-t-2">
      <Helmet>
        <title>Profile | FeelGoodEats</title>
        <meta name="description" content="Manage your account and reviews on FeelGoodEats." />
        <meta property="og:title" content="Profile | FeelGoodEats" />
        <meta property="og:description" content="Manage your account and reviews on FeelGoodEats." />
        <meta property="og:url" content="https://www.feelgoodeats.net/profile" />
      </Helmet>
      {/* Side Navigation */}
      <div className="w-full md:w-64 bg-white">
        <div className="p-6 max-w-sm mx-auto md:max-w-none">
          <h2 className="text-xl font-semibold mb-6">My Account</h2>
          <ul className="max-w-xs mx-auto md:max-w-none">
            <li className="mb-2">
              <button
                className={`w-full text-left py-2 px-4 rounded ${activeTab === 'profile' ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'}`}
                onClick={() => setActiveTab('profile')}
              >
                Profile
              </button>
            </li>
            <li className="mb-2">
              <button
                className={`w-full text-left py-2 px-4 rounded ${activeTab === 'reviews' ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'}`}
                onClick={() => setActiveTab('reviews')}
              >
                Reviews
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-4 md:p-10 w-full max-w-6xl mx-auto mb-10">
        {activeTab === 'profile' ? (
          <div>
            <h1 className="text-3xl font-bold mb-6">Your Profile</h1>
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
              </div>
            ) : (
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h2 className="text-2xl font-semibold mb-4">Your Information</h2>
                <p className="mb-2"><strong>Name:</strong> {currentUser.displayName || 'N/A'}</p>
                <p className="mb-4"><strong>Email:</strong> {currentUser.email}</p>
                <button 
                  onClick={handleSignOut}
                  className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 ease-in-out"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        ) : (
          <MyReviews />
        )}
      </div>
    </div>
  );
}

export default ProfilePage;
