import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import LoginModal from './LoginModal';
import { CgProfile } from 'react-icons/cg'; 
import { PiUserBold } from 'react-icons/pi';

function Header() {
  const { currentUser, signOut } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const dropdownRef = useRef(null);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleImageError = () => {
    setImageError(true);
    console.log("Failed to load profile image:", currentUser?.photoURL);
  };

  return (
      <div className='flex mx-auto w-full max-w-[1450px] items-center justify-between h-[80px] px-4 sm:px-8 md:px-14' aria-label='Global'>
        <div className=''>
          <a href='/'>
             <h1 className='text-2xl font-bold'>FeelGoodEats</h1>
          </a>
        </div>
       
      <div className='flex items-center justify-between gap-2 sm:gap-4'>
          <a href='/add-listing'>
            <button className='bg-indigo-500 text-sm font-medium text-white px-3 sm:px-4 py-2 shadow hover:bg-indigo-600 duration-300 rounded-md'>Add Listing</button>
          </a>
        {currentUser ? (
          <div className="relative" ref={dropdownRef}>
            <div 
              className="w-10 h-10 rounded-full cursor-pointer overflow-hidden"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {currentUser.photoURL && !imageError ? (
                <img 
                  src={currentUser.photoURL}
                  alt="Profile" 
                  className="w-full h-full object-cover"
                  onError={handleImageError}
                />
              ) : (
                <CgProfile 
                  className="w-full h-full text-gray-600"
                />
            )}
    </div>
            {showTooltip && (
              <div className="absolute bottom-[85%] left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-gray-600 bg-gray-100 rounded-md whitespace-nowrap shadow-sm">
                {currentUser.displayName || 'User'}
              </div>
            )}
            {isDropdownOpen && (
              <div className="z-50 absolute right-1/2 transform translate-x-1/2 mt-2 w-24 bg-white rounded-md shadow-lg outline-1 outline outline-gray-900/25">
                <a href='/profile'>
                  <button 
                    className="flex items-center justify-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 rounded-md"
                  >
                    <PiUserBold className="mr-2" />
                    Profile
                  </button>
                </a>
              </div>
            )}
          </div>
        ) : (
          <button 
            onClick={() => setIsLoginModalOpen(true)}
            className='bg-indigo-500 text-sm font-medium text-white px-3 sm:px-4 py-2 shadow hover:bg-indigo-600 duration-300 rounded-md'
          >
            Sign In
          </button>
        )}
      </div>
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
    </div>
  );
}

export default Header;