import React from 'react';
import StarRating from '../star-rating/StarRating.js';
import defaultImage from '../../images/default.jpeg';


function Result({ name, cuisine, street, city, state, rating, numReviews, imageURL, dietType }) {
  return (
    <div className='flex flex-row bg-white w-full rounded-md shadow-sm hover:shadow-md transition-shadow duration-300'>
      <RestaurantImage name={name} imageURL={imageURL} />
      <RestaurantInfo
        name={name}
        cuisine={cuisine}
        street={street}
        city={city}
        state={state}
        rating={rating}
        numReviews={numReviews}
        dietType={dietType}
      />
    </div>
  );
}

function RestaurantImage({ name, imageURL }) {
  return (
    <div className='restaurant-image h-24 w-24 sm:h-28 sm:w-28 flex-shrink-0'>
      <img
        src={imageURL && imageURL !== '' ? imageURL : defaultImage}
        alt={name}
        className='w-full h-full object-cover rounded-l-md'
      />
    </div>
  );
}

function RestaurantInfo({ name, cuisine, street, city, state, rating, numReviews, dietType }) {
  return (
    <div className='p-3 flex flex-col justify-between w-full'>
      <RestaurantHeader name={name} cuisine={cuisine} />
      <RatingInfo rating={rating} numReviews={numReviews} dietType={dietType} />
      <AddressInfo street={street} city={city} state={state} />
    </div>
  );
}

function RestaurantHeader({ name, cuisine }) {
  return (
    <div className='flex flex-wrap justify-between items-baseline mb-1 gap-y-1 min-w-0'>
      <h1 className='font-bold text-base sm:text-lg break-words mr-2 flex-grow' title={name}>
        {name}
      </h1>
      <h3 className='font-light text-sm sm:text-sm text-gray-600 break-words text-right'>
        {cuisine}
      </h3>
    </div>
  );
}

function RatingInfo({ rating, numReviews, dietType }) {
  const formattedRating = numReviews > 0 ? rating.toFixed(1) : 'N/A';
  
  return (
    <div className='flex flex-row items-center mb-1'>
      <h3 className='text-sm font-bold mr-1'>{formattedRating}</h3>
      <StarRating rating={rating} size={16} />
      <h3 className='text-xs sm:text-sm ml-1 text-gray-600'>
        ({numReviews} {dietType} {numReviews === 1 ? 'review' : 'reviews'})
      </h3>
    </div>
  );
}

function AddressInfo({ street, city, state }) {
  return (
    <div className='flex flex-wrap justify-between items-baseline w-full text-sm text-gray-600 gap-x-2 gap-y-1'>
      <span className='break-words mr-2'>{street}</span>
      <span className='break-words text-right'>{city}, {state}</span>
    </div>
  );
}

export default Result;
