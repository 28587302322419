import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";


const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function Searchform({
  borderInfo, 
  additionalStyles, 
  directions, 
  div1Styles, 
  div2Styles, 
  searchButtonStyles,
  directionsColor = 'text-white',
  directionsSize = 'text-md',
  inputStyles = 'px-3 py-2'
}) {

  const [locQuery, setLocQuery] = useState('');
  const [descQuery, setDescQuery] = useState('');
  const [locSuggestions, setLocSuggestions] = useState([]);
  const [descSuggestions, setDescSuggestions] = useState([]);
  const [showLocSuggestions, setShowLocSuggestions] = useState(false);
  const [showDescSuggestions, setShowDescSuggestions] = useState(false);
  const [requiredColor, setRequiredColor] = useState(directionsColor);
  const navigate = useNavigate();

  const fetchSuggestions = async (input, type) => {
    const endpoint = type === 'location' ? '/location-suggestions' : '/description-suggestions';

    // Send a GET request to the backend using the input as a query parameter
    const response = await fetch(`${REACT_APP_BACKEND_URL}${endpoint}?q=${input}`);
    const data = await response.json();
    return data;
  };


  const handleLocInputChange = async (event) => {
    const input = event.target.value;
    setLocQuery(input);
    if (input) {
      const { cityStateSuggestions, zipSuggestions } = await fetchSuggestions(input, 'location');
      const allSuggestions = [...cityStateSuggestions, ...zipSuggestions];
      setLocSuggestions(allSuggestions);
      setShowLocSuggestions(allSuggestions.length > 0);
    } else {
      setLocSuggestions([]);
      setShowLocSuggestions(false);
    }
  };

  const handleDescInputChange = async (event) => {
    const input = event.target.value;
    setDescQuery(input);
    if (input) {
      const { cuisineSuggestions, nameSuggestions } = await fetchSuggestions(input, 'description');
      const allSuggestions = [...cuisineSuggestions, ...nameSuggestions];
      setDescSuggestions(allSuggestions);
      setShowDescSuggestions(allSuggestions.length > 0);
    } else {
      setDescSuggestions([]);
      setShowDescSuggestions(false);
    }
  };


  const handleSuggestionClick = (suggestion, type) => {
    if (type === 'location') {
      setLocQuery(suggestion);
      setShowLocSuggestions(false);
    } else if (type === 'description') {
      setDescQuery(suggestion);
      setShowDescSuggestions(false);
    }
  };


  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (locQuery.trim() !== '') {
      // Redirect to results page with search queries
      navigate(`/results?loc=${encodeURIComponent(locQuery)}&desc=${encodeURIComponent(descQuery)}`);
      setRequiredColor(directionsColor);
    }
    else {
      setRequiredColor('text-red-500');
    }
  };

  const handleLocInputFocus = async () => {
    if (locQuery) {
      const { cityStateSuggestions, zipSuggestions } = await fetchSuggestions(locQuery, 'location');
      const allSuggestions = [...cityStateSuggestions, ...zipSuggestions];
      setLocSuggestions(allSuggestions);
      setShowLocSuggestions(allSuggestions.length > 0);
    } else {
      const { cityStateSuggestions, zipSuggestions } = await fetchSuggestions('', 'location');
      const allSuggestions = [...cityStateSuggestions, ...zipSuggestions];
      setLocSuggestions(allSuggestions);
      setShowLocSuggestions(allSuggestions.length > 0);
    }
  };

  const handleDescInputFocus = async () => {
    if (descQuery) {
      const { cuisineSuggestions, nameSuggestions } = await fetchSuggestions(descQuery, 'description');
      const allSuggestions = [...cuisineSuggestions, ...nameSuggestions];
      setDescSuggestions(allSuggestions);
      setShowDescSuggestions(allSuggestions.length > 0);
    } else {
      const { cuisineSuggestions, nameSuggestions } = await fetchSuggestions('', 'description');
      const allSuggestions = [...cuisineSuggestions, ...nameSuggestions];
      setDescSuggestions(allSuggestions);
      setShowDescSuggestions(allSuggestions.length > 0);
    }
  };


  return (
      <form onSubmit={handleSearchSubmit} className={`z-30 flex flex-col md:flex-row items-center md:items-start ${additionalStyles}`}>
        <div className={`relative md:mr-4 mb-2 md:mb-0 ${div1Styles}`}>
          <input
            className={`${borderInfo} w-full rounded-md ${showLocSuggestions ? 'rounded-b-none' : 'rounded-b-md'} ${inputStyles}`}
            type='text'
            placeholder='city, state, or zipcode'
            value={locQuery}
            onChange={handleLocInputChange}
            onFocus={handleLocInputFocus}
            onBlur={() => setShowLocSuggestions(false)}
          />
          {showLocSuggestions && (
            <ul className='absolute bg-white border rounded-md rounded-t-none w-full max-h-40 overflow-auto z-20'>
              {locSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className='px-4 py-2 cursor-pointer hover:bg-gray-200'
                  onMouseDown={() => handleSuggestionClick(suggestion, 'location')}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          {directions && (<h1 className={`font-medium ${requiredColor} ${directionsSize}`}>Required</h1>)}
        </div>
        <div className={`relative md:mr-4 mb-2 md:mb-0 ${div2Styles}`}>
          <input
            className={`${borderInfo} w-full rounded-md ${showDescSuggestions ? 'rounded-b-none' : 'rounded-b-md'} ${inputStyles}`}
            type='text'
            placeholder='cuisine or restaurant name'
            value={descQuery}
            onChange={handleDescInputChange}
            onFocus={handleDescInputFocus}
            onBlur={() =>  setShowDescSuggestions(false)}
          />
          {showDescSuggestions && (
            <ul className='absolute bg-white border rounded-md rounded-t-none w-full max-h-40 overflow-auto z-20'>
              {descSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className='px-4 py-2 cursor-pointer hover:bg-gray-200'
                  onMouseDown={() => handleSuggestionClick(suggestion, 'description')}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          {directions && (<h1 className={`font-medium ${directionsColor} ${directionsSize}`}>Optional</h1>)}
        </div>
        
        <button 
          type='submit'
          className={`bg-indigo-500 text-white shadow hover:bg-indigo-600 duration-300 rounded-md ${searchButtonStyles}`}>
          {/* Icon for medium and larger screens */}
          <span className='hidden md:inline'>
            <FaSearch className='text-xl'/>
          </span>
          {/* Text for smaller screens */}
          <span className='inline md:hidden text-sm'>
            Search
          </span>
        </button>
        

      </form>
  );
}

export default Searchform;