import {FaStar, FaStarHalfAlt} from 'react-icons/fa';


function StarRating({ rating, size}) {


  // Calculate the number of stars to display
  let numStars = 0;
  if (rating % 1 >= 0.3 && rating % 1 <= 0.7) {
    numStars = Math.floor(rating) + 0.5;
  }
  else {
    numStars = Math.round(rating);
  }

  const totalStars = 5; 
  const stars = [];

  // Loop for creating the stars
  for (let i = 0; i < totalStars; i++) {
    if (i + 1 <= numStars) {
      stars.push(<FaStar key={i} className='text-yellow-400' size={size} />);
    } 
    else if (i + 0.5 <= numStars) {
      stars.push(
        <div key={i} className='relative'>
          <FaStarHalfAlt className='text-yellow-400 absolute' style={{ clipPath: 'inset(0 50% 0 0)' }} size={size} /> 
          <FaStar className='text-gray-400' style={{ clipPath: 'inset(0 0 0 50%)' }} size={size} />
        </div>
      );
    } 
    else {
      stars.push(<FaStar key={i} className='text-gray-400' size={size} />);
    }
  }

  return (
    <div>
      <div className='flex flex-row items-center'>
        {stars}
      </div>
    </div>
    
  );
}

export default StarRating;