import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import Review from '../review/Review';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function MyReviews() {
  const { currentUser } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [totalReviews, setTotalReviews] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const pageSize = 5;

  useEffect(() => {
    fetchUserReviews();
  }, [currentUser]);

  const fetchUserReviews = async () => {
    if (!currentUser) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const idToken = await currentUser.getIdToken();
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/user-reviews?pageSize=${pageSize}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user reviews');
      }

      const data = await response.json();
      const updatedReviews = lastVisible ? [...reviews, ...data.reviews] : data.reviews;
      setReviews(updatedReviews);
      setLastVisible(data.lastVisible);
      setTotalReviews(data.totalReviews);
      setHasMore(updatedReviews.length < data.totalReviews);
    } catch (err) {
      console.error('Error fetching user reviews:', err);
      setError("Can't fetch your reviews at this time. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const loadMoreReviews = async () => {
    if (!hasMore || isLoadingMore) return;

    try {
      setIsLoadingMore(true);
      const idToken = await currentUser.getIdToken();
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/user-reviews?pageSize=${pageSize}&lastVisible=${lastVisible}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch more reviews');
      }

      const data = await response.json();
      const updatedReviews = [...reviews, ...data.reviews];
      setReviews(updatedReviews);
      setLastVisible(data.lastVisible);
      setHasMore(updatedReviews.length < data.totalReviews);
    } catch (err) {
      console.error('Error loading more reviews:', err);
      setError("Can't load more reviews at this time. Please try again later.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Your Reviews</h1>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : reviews.length === 0 ? (
        <p className="text-gray-600 italic">You haven't written any reviews yet.</p>
      ) : (
        <div>
          <p className="mb-4">Total Reviews: {totalReviews}</p>
          {reviews.map((review) => (
            <div key={review.id}>
              <div className="flex gap-2 items-center mb-2">
                <h3 className="text-lg font-bold">{review.restaurantName}</h3>
                <span className="text-gray-600">|</span>
                <p className="text-gray-600">{review.diet}</p>
              </div>
              <Review
                name={review.name}
                body={review.body}
                overall={review.rating.overall.toFixed(1)}
                taste={review.rating.taste.toFixed(1)}
                options={review.rating.options.toFixed(1)}
                comfort={review.rating.comfort.toFixed(1)}
                imageURL={review.imageURL}
                timestamp={review.timestamp}
                marginTop="mt-0"
                marginBottom="mb-6"
              />
            </div>
          ))}
          
          {hasMore && (
            <div className="flex justify-center mt-8">
              <button
                onClick={loadMoreReviews}
                disabled={isLoadingMore}
                className={`rounded-md px-4 py-2 text-white shadow-sm ${
                  isLoadingMore ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'
                }`}
              >
                {isLoadingMore ? 'Loading...' : 'Load More Reviews'}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MyReviews;
