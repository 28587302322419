import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, setPersistence, browserSessionPersistence, browserLocalPersistence, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../firebase/firebase';
import { FcGoogle } from 'react-icons/fc';
import { IoMdClose } from 'react-icons/io'; // Import close icon

function LoginModal({ isOpen, onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const { REACT_APP_BACKEND_URL } = process.env;

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);

      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        
        // Update profile with display name
        await updateProfile(user, { displayName: fullName });
        
        // Send verification email
        await sendEmailVerification(user);
        
        // Sign out the user immediately after registration
        await auth.signOut();
        alert("Please check your email to verify your account before signing in.");
        onClose();
      } else {
        // For sign in, first get the user without signing them in
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Check if email is verified
        if (!user.emailVerified) {
          // Sign out immediately if email isn't verified
          await auth.signOut();
          setError("Please verify your email before signing in. Check your inbox for the verification link.");
          return;
        }

        // If this is their first sign in after verification, create the Firestore user
        const idToken = await user.getIdToken();
        await fetch(`${REACT_APP_BACKEND_URL}/add-user`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        });

        console.log("User signed in successfully");
        onClose();
      }
    } catch (error) {
      setError(error.message);
      console.error("Error in authentication:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Create user in Firestore
      const idToken = await user.getIdToken();
      await fetch(`${REACT_APP_BACKEND_URL}/add-user`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });

      console.log("User signed in with Google successfully");
      onClose();
    } catch (error) {
      setError(error.message);
      console.error("Error signing in with Google:", error);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent. Please check your inbox.");
      setIsForgotPassword(false); // Return to login view
      setEmail(''); // Clear email field
    } catch (error) {
      setError(error.message);
      console.error("Error sending password reset email:", error);
    }
  };

  // Reset states when closing modal
  const handleClose = () => {
    setEmail('');
    setPassword('');
    setFullName('');
    setError('');
    setIsForgotPassword(false);
    setIsSignUp(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div className="relative bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
        <button 
          onClick={handleClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <IoMdClose size={24} />
        </button>

        {isForgotPassword ? (
          <>
            <h2 className="text-2xl font-bold text-center mb-6">Reset Password</h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            
            <form onSubmit={handleForgotPassword}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4"
                required
              />
              <button 
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              >
                Send Reset Link
              </button>
            </form>

            <div className="text-center mt-4">
              <button 
                onClick={() => setIsForgotPassword(false)}
                className="text-blue-500 hover:underline"
              >
                Back to Sign In
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-center mb-6">
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </h2>
            
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}

            <button 
              onClick={handleGoogleSignIn}
              className="w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded-md mb-4 flex items-center justify-center hover:bg-gray-50"
            >
              <FcGoogle className="mr-2" size={20} />
              Sign in with Google
            </button>

            <div className="flex items-center my-4">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="flex-shrink mx-4 text-gray-500">or</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>

            <form onSubmit={handleEmailSubmit}>
              {isSignUp && (
                <input
                  type="text"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4"
                  required
                />
              )}
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4"
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4"
                required
              />
              {!isSignUp && (
                <div className="flex justify-between items-center mb-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className="mr-2"
                    />
                    Remember me
                  </label>
                  <button
                    type="button"
                    onClick={() => setIsForgotPassword(true)}
                    className="text-blue-500 hover:underline"
                  >
                    Forgot Password?
                  </button>
                </div>
              )}
              <button 
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              >
                {isSignUp ? 'Sign Up' : 'Sign In'}
              </button>
            </form>

            <div className="text-center mt-4">
              <button 
                onClick={() => setIsSignUp(!isSignUp)}
                className="text-blue-500 hover:underline"
              >
                {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginModal;
