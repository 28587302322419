import { Helmet } from 'react-helmet';
import backgroundImage from '../../images/background3.jpg'
import icon from '../../images/FGE.png'
import review from '../../images/review-resize.png'
import search from '../../images/restaurant-resize.png'
import SearchForm from '../search-form/SearchForm';
import Review from '../review/Review';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


function Home() {
  const [recentReviews, setRecentReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentReviews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recent-reviews`);
        if (!response.ok) throw new Error('Failed to fetch reviews');
        const data = await response.json();
        setRecentReviews(data.reviews);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentReviews();
  }, []);

  return (
    <div className='relative min-h-screen'>
      <Helmet>
        <title>FeelGoodEats</title>
        <meta name="description" content="Find diet-specific restaurant reviews, recommendations, and photos. Your go-to resource for dining out with dietary restrictions." />
        <meta property="og:title" content="FeelGoodEats | Diet-Specific Restaurant Reviews" />
        <meta property="og:description" content="Discover restaurants that cater to your dietary needs. Read reviews, see photos, and find your next favorite dining spot." />
        <meta property="og:image" content={icon} />
        <meta property="og:url" content="https://www.feelgoodeats.net" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className='h-[550px] bg-cover bg-center w-full flex flex-col items-center justify-center relative' style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className='absolute inset-0 bg-black opacity-50 z-0'></div>
        <h1 className='w-11/12 md:w-full text-3xl md:text-4xl mb-8 text-white font-bold z-10 text-center'>
          Your resource for <span className='underline underline-offset-2'>diet-specific</span> restaurant reviews
        </h1>
        <SearchForm 
          borderInfo='border-none' 
          additionalStyles='w-full justify-center'
          directions={true} 
          searchButtonStyles='px-[10px] py-[10px]'
          div1Styles='w-11/12 md:w-1/4 lg:max-w-64'
          div2Styles='w-11/12 md:w-1/2 lg:max-w-lg'
        />
      </div>

      <div className='flex justify-center bg-gray-100'>

        <div className='flex flex-col w-3/4 max-w-[700px] my-10 md:my-14'>

          <div className='flex flex-col md:h-[210px] mb-5 md:mb-0 md:flex-row items-center justify-between'> 
            <div className='text-center md:text-left md:w-[45%]'>
              <h2 className='text-xl md:text-2xl font-bold mb-3'>Find a restaurant</h2>
              <p className='text-sm md:text-base'>
                If you can't find the one you're looking for, please help
                us out and add it to our database by clicking "Add Listing" 
              </p>
            </div>
            <img className='w-[45%] min-w-64' src={search} alt='person searching for a restaurant' />
          </div>
          
          <div className='flex flex-col md:h-[210px] md:flex-row items-center justify-between'>
            <img className='w-[45%] min-w-64 order-2 md:order-1' src={review} alt='person writing a review' />
            <div className='text-center md:text-left md:w-[45%] order-1 md:order-2'>
              <h2 className='text-xl md:text-2xl font-bold mb-3'>Write a review</h2>
              <p className='text-sm md:text-base'>
                Rate your dining experience based on taste, options, and how you felt afterwards 
                so others know what to expect 
              </p>
            </div>
          </div>

        </div>

      </div>
      <div className="bg-white pt-8 pb-16">
        <h1 className='text-2xl text-center font-bold mb-6'>Recent Reviews</h1>
        
        {loading && (
          <div className="text-center">
            <p>Loading reviews...</p>
          </div>
        )}

        {error && (
          <div className="text-center text-red-500">
            <p>Error loading reviews: {error}</p>
          </div>
        )}

        <div className="max-w-6xl mx-auto px-4">
          <div >
            {recentReviews.map((review) => (
              <div key={review.id}>
                <div className="flex gap-2 items-center mb-2">
                  <h3 className="text-lg font-bold">{review.restaurantName}</h3>
                  <span className="text-gray-600">|</span>
                  <p className="text-gray-600">{review.diet}</p>
                </div>
                <Link 
                  to={`/restaurants/${review.restaurantId}?diet=${review.diet}`}
                >
                  <Review
                    name={review.name}
                    body={review.body}
                    overall={review.rating.overall.toFixed(1)}
                    taste={review.rating.taste.toFixed(1)}
                    options={review.rating.options.toFixed(1)}
                    comfort={review.rating.comfort.toFixed(1)}
                    imageURL={review.imageURL}
                    timestamp={review.timestamp}
                    marginTop="mt-0"
                    marginBottom="mb-7"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home; 
