import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HiPhoto } from "react-icons/hi2";
import { FaStar } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import diets from '../../diets.js';
import { useAuth } from '../../contexts/authContext';
import { FaCheckCircle } from 'react-icons/fa';


// Get the backend URL from the environment variable
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


function Review() {
  const { id } = useParams(); // Get the restaurant ID from the URL parameter

  const { currentUser } = useAuth();
  const [selectedName, setSelectedName] = useState(currentUser?.displayName || "Anonymous Reviewer");
  const [isAnonymous, setIsAnonymous] = useState(!currentUser);

  const [dietType, setDietType] = useState('non-specific'); // State to store the diet type
  const [restaurantName, setRestaurantName] = useState(''); // State to store the restaurant name

  const [tasteRating, setTasteRating] = useState(null);
  const [optionsRating, setOptionsRating] = useState(null);
  const [comfortRating, setComfortRating] = useState(null);

  const [tasteHoveredRating, setTasteHoveredRating] = useState(null);
  const [optionsHoveredRating, setOptionsHoveredRating] = useState(null);
  const [comfortHoveredRating, setComfortHoveredRating] = useState(null);

  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  
  // Fetch the restaurant name based on the restaurant ID provided in the URL
  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await fetch(`${REACT_APP_BACKEND_URL}/restaurant/${id}`);
        const data = await response.json();
        setRestaurantName(data.name);
      } catch (error) {
        console.error('Error fetching restaurant:', error);
      }
    };

    fetchRestaurant();
  }, [id]);


  const handleRatingClick = (setRating, value) => {
    // If previous rating is the same as the current rating, reset the rating, else set the rating
    setRating(prev => (prev === value ? null : value));
  };


  // Submit the review
  const handleSubmit = async (e) => {
    
    // Prevent default form submission
    e.preventDefault();

    const formData = new FormData(e.target);
    
    // Get the body of the review
    const body = formData.get('body')?.trim() || '';
    formData.set('body', body);

    const newErrors = {}; 
    if (!selectedName) {newErrors.name = 'Field is required';}
    if (!dietType) {newErrors.dietType = 'Field is required';}
    if (!tasteRating) {newErrors.tasteRating = 'Field is required';}
    if (!optionsRating) {newErrors.optionsRating = 'Field is required';}
    if (!comfortRating) {newErrors.comfortRating = 'Field is required';}

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    const overallRating = (tasteRating + optionsRating + comfortRating) / 3;

    formData.append('restaurantId', id);
    formData.append('tasteRating', tasteRating.toFixed(1));
    formData.append('optionsRating', optionsRating.toFixed(1));
    formData.append('comfortRating', comfortRating.toFixed(1));
    formData.append('overallRating', overallRating);
    formData.append('isAnonymous', isAnonymous);
    
    // Append file if it exists, otherwise append an empty string
    formData.append('file', file || '');




    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/add-review`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${await currentUser?.getIdToken()}`, // Add this line
        },
        body: formData,
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to submit review');
      }
      setShowPopup(true);
    } catch (error) {
      console.error('Error submitting review:', error);
      // Handle the error (e.g., show an error message to the user)
    } finally {
      setIsSubmitting(false);  // Stop loading regardless of outcome
    }
  };

  const handleGoBack = () => {
    // Hide the pop-up and potentially reset the form or navigate back
    setShowPopup(false);

    // Redirect to the previous page
    window.history.back();
  };
  const renderStarRating = (rating, setRating, hoveredRating, setHoveredRating) => {
  return (
    <div className="flex space-x-1 mt-2">
      {[1, 2, 3, 4, 5].map((value) => (
      <button
        key={value}
        type='button'
        className={`${(value <= rating || value <= hoveredRating) ? 'text-yellow-400' : 'text-gray-400'}`}
        onClick={() => handleRatingClick(setRating, value)}
        onMouseEnter={() => setHoveredRating(value)}
        onMouseLeave={() => setHoveredRating(null)}
        aria-label={`Rate ${value} star`}
      >
        <FaStar size={40} />
      </button>
      ))}
    </div>
  );
  }

  // For handling image uploads
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file && file.type.substr(0, 5) === "image") {
      setImage(URL.createObjectURL(file));
      setFileName(file.name);
      setFile(file);
    } else {
      setImage(null);
      setFileName('');
      setFile(null);
    }
  };

  const removeImage = () => {
    setImage(null);
    setFileName('');
    setFile(null);
    // Reset the file input value
    const fileInput = document.getElementById('file-upload');
    if (fileInput) fileInput.value = '';
  };

  return (
    <div className="border-t-2 min-h-screen">
      <Helmet>
        <title>{`Write a Review for ${restaurantName} | FeelGoodEats`}</title>
        <meta name="description" content={`Share your dining experience at ${restaurantName}. Rate the taste, options, and comfort for your specific diet.`} />
        <meta property="og:title" content={`Write a Review for ${restaurantName} | FeelGoodEats`} />
        <meta property="og:description" content={`Share your dining experience at ${restaurantName}. Rate the taste, options, and comfort for your specific diet.`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.feelgoodeats.net/add-review/${id}`} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
 
      <div className='mx-4'>
        <form className="mt-12 mb-24 border-b border-gray-900/10 bg-slate-200 sm:mx-auto sm:max-w-[800px] shadow sm:rounded-lg px-6 py-12 sm:px-12" onSubmit={handleSubmit}>

          <h2 className="text-lg font-semibold leading-7 text-gray-700">Write a review for</h2>
          <h1 class="font-bold leading-7 text-gray-900 text-2xl">{restaurantName}</h1>
          <div className='mt-10 grid grid-cols-1 grid-rows-auto gap-y-2'>

          <div className='h-[88px]'>
            <label className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">Name</label>
            <select
              name="name"
              id="name"
              value={selectedName}
              onChange={(e) => {
                setSelectedName(e.target.value);
                setIsAnonymous(e.target.value === "Anonymous Reviewer");
              }}
              className="mt-2 block w-full sm:w-[215px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value="Anonymous Reviewer">Anonymous Reviewer</option>
              {currentUser && currentUser.displayName && (
                <option value={currentUser.displayName}>{currentUser.displayName}</option>
              )}
            </select>
            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
          </div>
           
            <div className='h-[88px]'>
              <label className="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">Diet type</label>
              <select
                id="diet"
                name="diet"
                value={dietType}
                className="mt-2 block w-full sm:w-[215px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => setDietType(e.target.value)}
              >
                {diets.map(diet => (
                  <option key={diet} value={diet} >
                    {diet}
                  </option>
                ))}
              </select>
              {errors.dietType && <p className="text-red-500 text-xs mt-1">{errors.dietType}</p>}
            </div>
            
            <div className='min-h-[117px]'> 
              <label class="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">Taste</label>
              <h3 className='block text-sm font-light leading-6'>How delicious was the food for your selected diet?</h3>
              {renderStarRating(tasteRating, setTasteRating, tasteHoveredRating, setTasteHoveredRating)}
              {errors.tasteRating && <p className="text-red-500 text-xs mt-1">{errors.tasteRating}</p>}
            </div>
                

            <div className='h-[117px]'>
              <label class="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">Options</label>
              <h3 className='block text-sm font-light leading-6'>How varied were the choices for your selected diet?</h3>
              {renderStarRating(optionsRating, setOptionsRating, optionsHoveredRating, setOptionsHoveredRating)}
              {errors.optionsRating && <p className="text-red-500 text-xs mt-1">{errors.optionsRating}</p>}
            </div>
              
            <div className='h-[117px] max-[527px]:h-[141px]'>
              <label class="block text-sm font-medium leading-6 text-gray-900 after:content-['*'] after:text-red-500">Comfort</label>
              <h3 className='block text-sm font-light leading-6'>How did you feel physically after your meal, considering your selected diet?</h3>
              {renderStarRating(comfortRating, setComfortRating, comfortHoveredRating, setComfortHoveredRating)}
              {errors.comfortRating && <p className="text-red-500 text-xs mt-1">{errors.comfortRating}</p>}
            </div>
      
            <div className='h-[185px]'>
              <label className="block text-sm font-medium leading-6 text-gray-900 after:content-['(optional)'] after:font-light">Share your experience </label>
              <textarea
                id="body"
                name="body"
                rows={5}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                style={{ resize: 'none' }}
                defaultValue={''}
              />
            </div>

            <div className="h-[324px]">
            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900 after:content-['(optional)'] after:font-light ">Cover photo </label>
            <div 
              className="mt-2 flex flex-col justify-center items-center border-2 border-dashed border-gray-900/25 rounded-md p-4 h-[270px]"
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              {image ? (
                <div className="relative w-full flex flex-col justify-center items-center">
                  <img src={image} alt="Preview" className="h-40 w-auto" />
                  <button 
                    onClick={removeImage} 
                    className="absolute top-0 right-0 bg-red-500 hover:bg-red-600 text-white rounded-md p-1"
                    aria-label="Remove image"
                  >
                    <IoMdClose size={20} />
                  </button>
                  <p className="mt-2 text-sm text-gray-500">{fileName}</p>
                </div>
              ) : (
                <>
                  <HiPhoto className="mx-auto h-40 w-40 text-gray-300" aria-hidden="true" />
                  <p className="mt-2 text-sm">Drag and drop an image here, or click to select a file</p>
                </>
              )}
              <input 
                id="file-upload"
                type="file"
                accept="image/*" 
                onChange={handleChange} 
                className="hidden"
              />
              <label 
                htmlFor="file-upload" 
                className="mt-2 cursor-pointer text-sm inline-block bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-md"
              >
                Select File
              </label>
            </div>
          </div>
             
               
          </div>
            
            <div className="mt-8 flex items-center justify-end gap-x-6">
              <button type="button" onClick={handleGoBack} className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
              <button type="submit" 
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>

        </form>
      </div>

      {isSubmitting && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
        </div>  
      )}


      {/* Pop-up message */}
      { showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 m-4 max-w-sm w-full">
            <div className="flex flex-col items-center">
              <FaCheckCircle className="text-green-500 text-5xl mb-4" />
              <h2 className="text-2xl font-bold mb-4 text-center">Thank You!</h2>
              <p className="text-gray-700 text-center mb-6">
                Your review has been successfully submitted. We appreciate your feedback!
              </p>
              <button
                onClick={handleGoBack}
                className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-500"
              >
                Go back
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Review;



