import StarRating from '../star-rating/StarRating.js';
import { parseISO } from 'date-fns';

function Review({ name, body, overall, taste, options, comfort, imageURL, timestamp, marginTop = 'mt-6', marginBottom = 'mb-6' }) {
  let formattedDate = 'Unknown date';

  if (timestamp) {
    try {
      let date;
      if (typeof timestamp === 'string') {
        date = parseISO(timestamp);
      } else if (timestamp.seconds && timestamp.nanoseconds) {
        date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
      }

      if (date) {
        const now = new Date();
        const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
        
        if (diffInDays >= 365) {
          const years = Math.floor(diffInDays / 365);
          formattedDate = `${years} ${years === 1 ? 'year' : 'years'} ago`;
        } else if (diffInDays >= 30) {
          const months = Math.floor(diffInDays / 30);
          formattedDate = `${months} ${months === 1 ? 'month' : 'months'} ago`;
        } else if (diffInDays >= 7) {
          const weeks = Math.floor(diffInDays / 7);
          formattedDate = `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
        } else if (diffInDays >= 1) {
          formattedDate = `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
        } else {
          const hours = Math.floor((now - date) / (1000 * 60 * 60));
          if (hours >= 1) {
            formattedDate = `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
          } else {
            const minutes = Math.floor((now - date) / (1000 * 60));
            formattedDate = `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
          }
        }
      }
    } catch (error) {
      console.error('Error formatting date:', error);
    }
  }

  return (
    <div className={`bg-white rounded-lg shadow-md overflow-hidden ${marginTop} ${marginBottom}`}>
      <div className="p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
          <h2 className="text-lg sm:text-xl font-bold mr-0 sm:mr-4">{name}</h2>
          <p className="text-xs sm:text-sm text-gray-500">{formattedDate}</p>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="grid grid-cols-2 sm:flex gap-x-5 gap-y-3">
            {[
              { label: "Overall", value: overall },
              { label: "Taste", value: taste },
              { label: "Options", value: options },
              { label: "Comfort", value: comfort }
            ].map((item) => (
              <div key={item.label} className={`${item.label === "Overall" ? "border-r border-gray-500 pr-5" : ""}`}>
                <h4 className="text-xs sm:text-sm font-medium text-gray-500">{item.label}</h4>
                <div className="flex items-center">
                  <span className="text-base sm:text-lg font-semibold mr-2">{item.value}</span>
                  <StarRating rating={parseFloat(item.value)} size={16} />
                </div>
              </div>
            ))}
          </div>
        </div>   
        {body && <p className="text-gray-700 mt-4 text-xs sm:text-base">{body}</p>}
        
        {imageURL && (
          <div className="w-full h-32 sm:h-40 mt-4 flex items-center justify-center bg-gray-100 rounded-lg overflow-hidden">
            <img 
              src={imageURL} 
              alt="Review" 
              className="max-w-full max-h-full object-contain"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Review;
