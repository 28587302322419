import { Helmet } from 'react-helmet';

function PrivacyPage() {
  return (
    <div className="border-t-2 min-h-screen bg-gray-100">
      <Helmet>
        <title>Privacy Policy | FeelGoodEats</title>
        <meta name="description" content="FeelGoodEats Privacy Policy. Learn how we collect, use, and protect your information on our diet-specific restaurant reviews platform." />
        <meta property="og:title" content="Privacy Policy | FeelGoodEats" />
        <meta property="og:description" content="FeelGoodEats Privacy Policy. Learn how we collect, use, and protect your information on our diet-specific restaurant reviews platform." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.feelgoodeats.net/privacy" />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <h1 className="text-3xl font-bold mb-2 text-center">Privacy Policy</h1>
        <p className="text-sm text-gray-600 mb-6 text-center">Last Updated: Oct 19, 2024</p>
        
        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
          <p className="mb-4">
            We collect information you provide directly to us, such as when you create an account, submit a review, or contact us. This may include:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Name and email address</li>
            <li>Dietary preferences</li>
            <li>Restaurant reviews and ratings</li>
            <li>Photos you upload</li>
          </ul>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
          <p className="mb-4">
            We use the information we collect to:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide, maintain, and improve our services</li>
            <li>Process and display your reviews and ratings</li>
            <li>Communicate with you about our services</li>
            <li>Monitor and analyze trends and usage</li>
          </ul>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Information Sharing</h2>
          <p className="mb-4">
            We do not sell your personal information. We may share your information in the following situations:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>With your consent</li>
            <li>To comply with legal obligations</li>
            <li>To protect the rights and safety of FeelGoodEats and our users</li>
          </ul>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Your Choices</h2>
          <p className="mb-4">
            You can request to have your account and associated data deleted by contacting us. For any questions about your data or this privacy policy, please reach out to us using the contact information provided on our website.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Security</h2>
          <p className="mb-4">
            We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access.
          </p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-16">
          <h2 className="text-2xl font-semibold mb-4">6. Changes to This Policy</h2>
          <p className="mb-4">
            We may update this privacy policy from time to time. When we do, we will revise the "Last Updated" date at the top of this page. We encourage you to review this policy periodically to stay informed about our practices. Continued use of our services after any modifications to the privacy policy constitutes your acceptance of such changes.
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPage;
