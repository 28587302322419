import { Helmet } from 'react-helmet';

function ContactPage() {
  return (
    <div className="border-t-2 min-h-screen bg-gray-100">
      <Helmet>
        <title>Contact | FeelGoodEats</title>
        <meta name="description" content="Contact FeelGoodEats for questions, suggestions, or feedback about our diet-specific restaurant review platform." />
        <meta property="og:title" content="Contact | FeelGoodEats" />
        <meta property="og:description" content="Contact FeelGoodEats for questions, suggestions, or feedback about our diet-specific restaurant review platform." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.feelgoodeats.net/contact" />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className="container mx-auto px-4 py-8 max-w-3xl">       
        <section className="bg-white shadow-md rounded-lg p-6 mb-16">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="mb-4">
            Have questions, suggestions, or feedback? We'd love to hear from you! Reach out to us at:
          </p>
          <a 
            href="mailto:hello@feelgoodeats.net?subject=FeelGoodEats%20Inquiry" 
            className="text-indigo-600 hover:text-indigo-800 hover:underline"
          >
            hello@feelgoodeats.net
          </a>
        </section>
      </div>
    </div>
  );
}

export default ContactPage;
