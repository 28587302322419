import React from 'react';

function DietButton({diet, currentDiet, onClick}) {

  // For checking if the current diet button is active
  const isActive = diet === currentDiet;

  return (
    <button 
      className={`
        text-xs font-medium px-2 py-2 rounded-md shadow-sm transition-colors duration-200 min-[900px]:text-sm min-[900px]:px-3 min-[900px]:py-2
        ${isActive 
          ? 'bg-teal-600 text-white hover:bg-teal-700' 
          : 'bg-white text-gray-700 hover:bg-teal-50 hover:text-teal-600 border border-gray-300'
        }
      `}
      onClick={onClick}
    >
      {diet}
    </button>
  );
}

export default DietButton;