import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Result from "../result/Result.js";
import GoogleMap from '../google-map/GoogleMap.js';
import DietButton from '../diet-button/DietButton.js';
import SearchForm from '../search-form/SearchForm.js';

import diets from '../../diets.js';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function ResultsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [displayedRestaurants, setDisplayedRestaurants] = useState([]);
  const [displayedRestaurantsWithCoords, setDisplayedRestaurantsWithCoords] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search); // Get the query parameters from the URL
  const locQuery = queryParams.get('loc');
  const descQuery = queryParams.get('desc');
  const initialDiet = queryParams.get('diet') || 'non-specific'; // Get the diet type from the query parameters, default to 'general' if not provided
  const [dietType, setDietType] = useState(initialDiet);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hoveredRestaurant, setHoveredRestaurant] = useState(null);
  const pageSize = 7; // Number of restaurants to display per page

  // Fetch all restaurant data
  useEffect(() => {
    console.log('Fetching restaurants, isLoading:', isLoading);
    const fetchRestaurants = async () => {
      setIsLoading(true); // Ensure loading is true when fetching
      setDisplayedRestaurantsWithCoords([]); // Clear previous results
      console.log('Started fetching, isLoading set to true');
      
      try {
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/restaurants?` +
          `locQuery=${locQuery || ''}&` +
          `descQuery=${descQuery || ''}&` +
          `dietType=${dietType}`
        );
        const data = await response.json();
        setAllRestaurants(data.restaurants);
        setTotalPages(Math.ceil(data.restaurants.length / pageSize));
        if (data.restaurants.length === 0) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchRestaurants();
  }, [dietType, locQuery, descQuery]);

  // Handle pagination locally
  useEffect(() => {
    console.log('Pagination effect, allRestaurants:', allRestaurants.length);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedRestaurants(allRestaurants.slice(startIndex, endIndex));
  }, [currentPage, allRestaurants, pageSize]);

  // Get coordinates only for displayed restaurants
  useEffect(() => {
    const getCoordinates = async () => {
      try {
        const restaurantsWithCoords = await Promise.all(
          displayedRestaurants.map(async restaurant => {
            const address = `${restaurant.address.street}, ${restaurant.address.city}, ${restaurant.address.stateFull}`;
            const response = await fetch(`${REACT_APP_BACKEND_URL}/get-coordinates`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ address })
            });
            const data = await response.json();
            return {
              ...restaurant,
              coordinates: data.lat && data.lng ? data : null
            };
          })
        );
        setDisplayedRestaurantsWithCoords(restaurantsWithCoords);
        setIsLoading(false); // Only set loading to false after coordinates are fetched
      } catch (error) {
        console.error("Error fetching coordinates:", error);
        setIsLoading(false);
      }
    };

    if (displayedRestaurants.length > 0) {
      getCoordinates();
    } else {
      // Only set loading to false if we've tried to fetch restaurants and got none
      if (allRestaurants.length === 0 && !isLoading) {
        setIsLoading(false);
      }
    }
  }, [displayedRestaurants]);

  const handleDietChange = (diet) => {
    setIsLoading(true); // Set loading when changing diet
    setDietType(diet);
    setCurrentPage(1);
    queryParams.set('diet', diet);
    navigate({ search: queryParams.toString() });
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxVisibleButtons = 5;

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (currentPage > 3) {
        pageNumbers.push('...');
      }

      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    }

    return (
      <div className="flex justify-center items-center mt-4 space-x-2">
        <button 
          className={`rounded-md px-3 py-1 text-sm text-white shadow-sm ${
            currentPage > 1 ? 'bg-indigo-500 hover:bg-indigo-600' : 'bg-gray-300 cursor-not-allowed'
          }`}
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          &laquo;
        </button>
        {pageNumbers.map((number, index) => (
          <button
            key={index}
            className={`rounded-md px-3 py-1 text-sm shadow-sm ${
              number === '...' 
                ? 'bg-white text-gray-600 cursor-default'
                : currentPage === number 
                  ? 'bg-indigo-600 text-white' 
                  : 'bg-white text-indigo-600 hover:bg-indigo-100'
            }`}
            onClick={() => number !== '...' && setCurrentPage(number)}
            disabled={number === '...'}
          >
            {number}
          </button>
        ))}
        <button 
          className={`rounded-md px-3 py-1 text-sm text-white shadow-sm ${
            currentPage < totalPages ? 'bg-indigo-500 hover:bg-indigo-600' : 'bg-gray-300 cursor-not-allowed'
          }`}
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage >= totalPages}
        >
          &raquo;
        </button>
      </div>
    );
  };

  return (
    <div className="px-5 md:px-[3vw] bg-gray-50 flex flex-col items-center min-h-screen border-t-2">
      <Helmet>
        <title>{`${dietType} Restaurant Results | FeelGoodEats`}</title>
        <meta name="description" content={`Find ${dietType} friendly restaurants. Browse reviews, ratings, and recommendations for your dietary needs.`} />
        <meta property="og:title" content={`${dietType} Restaurant Results | FeelGoodEats`} />
        <meta property="og:description" content={`Find ${dietType} friendly restaurants. Browse reviews, ratings, and recommendations for your dietary needs.`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.feelgoodeats.net/results?diet=${dietType}`} />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className='flex flex-col md:flex-row md:items-center justify-between w-full max-w-[1300px] mt-4 mb-6'>
        <h1 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-0">
          Restaurant ratings based on <span className='underline'>diet</span>
        </h1>
        <SearchForm 
          borderInfo = 'border border-slate-400' 
          additionalStyles = 'w-full md:w-[50%] justify-end' 
          directions={true} 
          div1Styles='w-full md:w-1/3' 
          div2Styles='w-full md:w-1/2'
          searchButtonStyles='py-2 px-3 md:px-[9px] md:py-[9px]'
          directionsColor='text-gray-600'
          directionsSize='text-sm'
          inputStyles='py-2 px-3 text-sm'
        />
      </div>
      
      <div className='flex flex-col w-full items-start max-w-[1300px] mb-4'>
        <div className="mb-4 flex flex-wrap gap-2">
          {diets.map(diet => (
            <DietButton 
              key={diet}
              diet={diet}
              currentDiet={dietType}
              onClick={() => handleDietChange(diet)}
            />
          ))}
        </div>
        <hr className='w-full bg-gray-300 h-[2px]'/>
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      ) : displayedRestaurantsWithCoords.length > 0 ? (
        <div className='max-h-[750px] w-full max-w-[1300px] flex flex-col min-[900px]:flex-row justify-between min-[900px]:h-calc-full-minus-240 max-[900px]:items-center mb-12'>
          <div className='relative max-[900px]:h-[395px] bg-white flex flex-col w-full min-[900px]:max-w-[700px] max-w-[530px] md:min-w-[500px] md:mr-5 rounded-lg overflow-hidden'>
            <div className='absolute top-0 bottom-0 right-0 left-0 pointer-events-none z-20' 
                style={{'--tw-shadow': 'inset 0 2px 6px 0 rgb(0 0 0 / 0.3)',
                        '--tw-shadow-colored': 'inset 0 2px 4px 0 var(--tw-shadow-color)',
                        boxShadow: 'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)'}}>       
            </div>
   
            <div className='overflow-y-auto z-10 p-4 flex flex-col'>
              {/* Map through current batch of restaurants data and create restaurant components */}
              {displayedRestaurantsWithCoords.map(restaurant => (
              <a key={restaurant.id} 
                  href={`/restaurants/${restaurant.id}?diet=${dietType}`}
                  className='mb-3 border rounded-md hover:border-red-400'
                  onMouseEnter={() => setHoveredRestaurant(restaurant.id)}
                  onMouseLeave={() => setHoveredRestaurant(null)}
                  >
                <Result
                  name={restaurant.name}
                  cuisine={restaurant.cuisine}
                  street={restaurant.address.street}
                  city={restaurant.address.city}
                  state={restaurant.address.stateAbbrv}
                  rating={restaurant.reviewStats[dietType].averageOverall}
                  numReviews={restaurant.reviewStats[dietType].totalReviews}
                  imageURL={restaurant.latestImages[dietType] || '' }
                  dietType={dietType}
                />
              </a>
              ))}
              {renderPagination()}
            </div>
          </div>
    
          <GoogleMap 
            restaurants={process.env.REACT_APP_GOOGLE_API_KEY ? displayedRestaurantsWithCoords : []} 
            hoveredRestaurant={hoveredRestaurant} 
            dietType={dietType}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-64">
          <p className="text-xl font-semibold text-gray-700 mb-4">No results found</p>
          <p className="text-gray-600 text-center">Please try adjusting your search query or dietary preferences.</p>
        </div>
      )}
    </div>
  );
}

export default ResultsPage;
